import gsap from 'gsap';
import { CountUp } from 'countup.js';
import barba from '@barba/core';
import { ease1 } from '@/animations/easings';
import { sessionStorageUtility } from '@/utils/sessionStorageUtility';
import { ESessionStorageFields, SESSION_STORAGE_VALUES } from '@/types/session-storage';

export const preloaderAnimationDuration = 2; // sec

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    const preloaderBottom = document.querySelector<HTMLElement>('.js-preloader-bottom');
    const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
    const line = document.querySelector<HTMLElement>('.js-preloader-line');
    const logoContainer = document.querySelector<HTMLElement>('.js-preloader-logo');
    const logoWrapper = document.querySelector<HTMLElement>('.js-preloader-grayscale-logo-wrapper');
    const logo = document.querySelector<HTMLElement>('.js-preloader-grayscale-logo');
    const colors = ['#1b1b1b', '#E40088', '#22B14C'];
    let currentColor = colors[0];
    let loaded = false;

    const state = {
        completed: false,
        currentPercent: 0,
    };

    function getColor(percent: number) {
        if (percent <= 34) {
            return colors[0];
        }

        if (percent > 34 && percent <= 89) {
            return colors[1];
        }

        return colors[2];
    }

    document.body.classList.add('no-scroll');

    const isStartPreloaderPlayed = !!sessionStorageUtility.get(ESessionStorageFields.isStartPreloaderPlayed);

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.dispatchEvent(new Event('preloader-leave'));

            barba.hooks.afterEnter(() => {
                if (!loaded) {
                    loaded = true;
                }
            });

            const tl = gsap.timeline();

            tl.to(
                [logoContainer, preloaderBottom],
                {
                    duration: 1,
                    opacity: 0,
                    y: 100,
                    ease: ease1,
                    onComplete: () => {
                        state.completed = true;
                        document.body.classList.remove('no-scroll');
                    },
                },
                isStartPreloaderPlayed ? 0 : 0.5,
            ).add(() => {
                resolve();
                setTimeout(() => {
                    preloader?.remove();
                }, 300);
            });

            tl.to(
                preloader,
                {
                    opacity: 0,
                    ease: ease1,
                    duration: 1,
                },
                '-=1',
            );
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    asset.addEventListener('canplay', () => resolve(), { once: true });
                }
            }
        });
    }

    const counterInstance = counter
        ? new CountUp(counter, 0, {
              startVal: 0,
              useEasing: true,
              duration: 2,
          })
        : null;

    function setPercent(value: number) {
        counterInstance?.update(value);

        if (line) {
            line.style.transform = `scaleX(${value / 100})`;
        }

        gsap.to(state, {
            duration: 2,
            overwrite: true,
            currentPercent: value,
            onUpdate: () => {
                const roundedValue = Math.round(state.currentPercent);
                const color = getColor(roundedValue);

                if (preloader && currentColor !== color) {
                    preloader.style.color = color;
                    currentColor = color;
                }
            },
        });

        if (logoWrapper && logo) {
            gsap.to(logoWrapper, { duration: 2, overwrite: true, xPercent: value });
            gsap.to(logo, { duration: 2, overwrite: true, xPercent: -value });
        }
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const loadingPromise = new Promise<void>((resolve) => {
            const images = Array.from(
                element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'),
            );
            const videos = Array.from(
                element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'),
            );
            const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

            // Фоллбек чтобы загрузка прошла в любом случае
            setTimeout(resolve, 2500);

            if (images.length > 0) {
                Promise.all<any>(assets.map((asset) => loadAsset(asset))).then(() => resolve());
            } else {
                resolve();
            }
        });

        await loadingPromise;
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
        setPercent(100);
    }

    const playAnimation = (): Promise<void> =>
        new Promise(async (resolve) => {
            if (!isStartPreloaderPlayed) {
                gsap.fromTo(
                    [logoContainer, preloaderBottom],
                    { opacity: 0, y: 60 },
                    { duration: 1, opacity: 1, y: 0, ease: ease1 },
                );

                gsap.to(logoContainer, {
                    duration: 1,
                    yPercent: -50,
                    ease: ease1,
                    onComplete: () => {
                        setTimeout(async () => {
                            await leave();
                            resolve();
                        }, 1200);
                    },
                });

                sessionStorageUtility.set(
                    ESessionStorageFields.isStartPreloaderPlayed,
                    SESSION_STORAGE_VALUES.isStartPreloaderPlayed.played,
                );
            } else {
                await leave();
                resolve();
            }
        });

    return { loadAssets, state, playAnimation } as const;
}

export const preloader = createPreloader();
