const getParamterTheme = new URL(window.location.href).searchParams.get('theme');
let currentTheme = getParamterTheme || localStorage.getItem('theme') || 'light';
let videos: HTMLVideoElement[];
const headerThemeElements = document.querySelectorAll('[data-theme]');
const switcherTheme = Array.from(document.querySelectorAll('.js-switch-theme'));
let lightThemeSlider: HTMLElement | null;
let darkThemeSlider: HTMLElement | null;
let slide3DTemplate: HTMLElement | null;

export function clearGetParam() {
    const url = new URL(window.location.href);

    if (url.searchParams.has('theme')) {
        url.searchParams.delete('theme');
        window.history.replaceState({}, '', url.toString());
    }
}

export function setThemeInLocalStorage(currentTheme: string) {
    localStorage.setItem('theme', currentTheme);
}

function cutSlide() {
    if (lightThemeSlider && darkThemeSlider && slide3DTemplate) {
        if (currentTheme === 'dark') {
            darkThemeSlider.appendChild(slide3DTemplate);
        } else {
            lightThemeSlider.appendChild(slide3DTemplate);
        }
    }
}

function switchThemeTo(theme: string) {
    currentTheme = theme;

    clearGetParam();
    videos.forEach((video) => {
        video.play();
    });

    document.body.classList.remove('light-theme', 'dark-theme');
    document.body.classList.add(`${theme}-theme`);

    if (theme === 'dark') {
        //временное решение с шапкой
        headerThemeElements.forEach((element) => {
            const newTheme = 'transparent';
            element.setAttribute('data-theme', newTheme);
        });
    }

    setThemeInLocalStorage(theme);
    cutSlide();
}

const onClick = (event: Event) => {
    event.preventDefault();
    switchThemeTo(currentTheme === 'dark' ? 'light' : 'dark');

    document.dispatchEvent(
        new CustomEvent('change-theme', {
            detail: {
                theme: currentTheme,
            },
        }),
    );
};

function init(container: HTMLElement | Document = document) {
    videos = Array.from(container.querySelectorAll<HTMLVideoElement>('video[autoplay]'));
    slide3DTemplate = container.querySelector<HTMLElement>('.js-slide-3d');
    lightThemeSlider = container.querySelector<HTMLElement>('.gallery-light .swiper-wrapper .js-slide-3d-container');
    darkThemeSlider = container.querySelector<HTMLElement>('.gallery-dark .swiper-wrapper .js-slide-3d-container');

    switchThemeTo(currentTheme);

    switcherTheme.forEach(function (btn) {
        btn.addEventListener('click', onClick);
    });
}

function destroy(container: HTMLElement | Document = document) {
    switcherTheme.forEach(function (btn) {
        btn.removeEventListener('click', onClick);
    });
    videos = [];
    slide3DTemplate = null;
    lightThemeSlider = null;
    darkThemeSlider = null;
}

const _module = { init, destroy };

export default _module;
