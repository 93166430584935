let instance: any;

function init(container: HTMLElement | Document = document) {
    const canvas = container.querySelector<HTMLCanvasElement>('canvas.js-product-canvas');

    if (canvas) {
        const modelName = canvas.dataset.modelName || '';

        if (modelName) {
            import('../components/product-3d' /* webpackChunkName: "product-3d" */).then(({ createProductModel }) => {
                instance = createProductModel(canvas, modelName);
            });
        }
    }
}

function destroy() {
    if (instance) {
        instance.destroy();
        instance = null;
    }
}

export default { init, destroy };
