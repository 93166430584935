import type { Swiper as SwiperType } from 'swiper';
import gsap from 'gsap';
import { ease1 } from '@/animations/easings';
import { fetchSwiper } from '../../../dynamic-modules';
import { DEVICE_WIDTH } from '../../../utils/viewport-width';
import { createNav, destroyNav, createCounter } from '../../../inits/base-sliders';

export const map = new Map<Element, any>();

async function init(container: HTMLElement | Document = document) {
    const reviewsSlider = container.querySelector<HTMLElement>('.js-related-articles-slider');

    if (reviewsSlider) {
        const slides = Array.from(reviewsSlider.querySelectorAll<HTMLElement>('.swiper-slide'));
        if (slides.length > 1) {
            const { Swiper, Lazy } = await fetchSwiper();
            const state = { isAnimating: false };

            const changeSlide = (swiper: SwiperType) => {
                const isNextDirection =
                    (swiper.previousIndex < swiper.activeIndex ||
                        (swiper.previousIndex === slides.length - 1 && swiper.activeIndex === 0)) &&
                    !(swiper.previousIndex === 0 && swiper.activeIndex === slides.length - 1);

                const currentImg = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.a-reviews-slider-slide__img-wrapper',
                );
                const currentTitle = swiper.slides[swiper.previousIndex].querySelector<HTMLElement>(
                    '.a-reviews-slider-slide__title',
                );
                const currentBtn =
                    swiper.slides[swiper.previousIndex].querySelector<HTMLElement>('.a-reviews-slider-slide__btn');
                const nextTitle = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.a-reviews-slider-slide__title',
                );
                const nextImg = swiper.slides[swiper.activeIndex].querySelector<HTMLElement>(
                    '.a-reviews-slider-slide__img-wrapper',
                );
                const nextBtn =
                    swiper.slides[swiper.activeIndex].querySelector<HTMLElement>('.a-reviews-slider-slide__btn');

                const tl = gsap.timeline({
                    defaults: { ease: ease1 },
                    onStart: () => {
                        state.isAnimating = true;
                        swiper.allowTouchMove = false;
                    },
                    onComplete: () => {
                        state.isAnimating = false;

                        if (!DEVICE_WIDTH.isDesktop) {
                            swiper.allowTouchMove = true;
                        }
                    },
                });

                if (!DEVICE_WIDTH.isDesktop) {
                    tl.fromTo(
                        [currentTitle, currentBtn],
                        { y: 0, opacity: 1 },
                        {
                            duration: 0.6,
                            y: 15,
                            opacity: 0,
                            stagger: 0.1,
                        },
                    )
                        .fromTo(
                            currentImg,
                            { xPercent: 0 },
                            { xPercent: isNextDirection ? -100 : 100, duration: 1, ease: 'power3.inOut' },
                            '-=0.3',
                        )
                        .fromTo(
                            nextImg,
                            { xPercent: isNextDirection ? 100 : -100 },
                            { xPercent: 0, duration: 1, ease: 'power3.inOut' },
                            '-=1',
                        )
                        .fromTo(
                            [nextTitle, nextBtn],
                            { y: 15, opacity: 0 },
                            {
                                duration: 0.6,
                                y: 0,
                                opacity: 1,
                                stagger: 0.1,
                            },
                        );
                } else {
                    tl.fromTo(
                        [currentTitle, currentBtn],
                        { y: 0, opacity: 1 },
                        {
                            duration: 0.6,
                            y: 15,
                            opacity: 0,
                            stagger: 0.1,
                        },
                    )
                        .fromTo(
                            currentImg,
                            { xPercent: 0 },
                            {
                                xPercent: isNextDirection ? -100 : 100,
                                duration: 1,
                                ease: 'power3.inOut',
                                onComplete: () => {
                                    gsap.set(currentImg, {
                                        opacity: 0,
                                        pointerEvents: 'none',
                                    });
                                },
                            },
                            '-=0.9',
                        )
                        .fromTo(
                            nextImg,
                            { xPercent: isNextDirection ? 100 : -100, opacity: 1, pointerEvents: 'all' },
                            { xPercent: 0, duration: 1, ease: 'power3.inOut' },
                            '-=1',
                        )
                        .fromTo(
                            [nextTitle, nextBtn],
                            { y: 15, opacity: 0 },
                            {
                                duration: 0.6,
                                y: 0,
                                opacity: 1,
                                stagger: 0.1,
                            },
                        );
                }
            };

            const slider = new Swiper(reviewsSlider, {
                modules: [Lazy],
                allowTouchMove: !DEVICE_WIDTH.isDesktop,
                slidesPerView: 1,
                watchSlidesProgress: true,
                virtualTranslate: true,
                speed: 0,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                    checkInView: true,
                },
                rewind: true,
                on: {
                    init: (swiper) => {
                        if (
                            typeof swiper.params.slidesPerView === 'number' &&
                            slides.length <= swiper.params.slidesPerView
                        ) {
                            swiper.disable();
                        }

                        slides
                            .filter((_, i) => i !== swiper.activeIndex)
                            .forEach((el) => {
                                gsap.set(el.querySelector('.a-reviews-slider-slide__img-wrapper'), {
                                    xPercent: 100,
                                });
                            });
                    },
                    afterInit: (swiper) => {
                        const sliderNavs = Array.from(reviewsSlider.querySelectorAll<HTMLElement>('.js-slider-nav'));
                        const counters = Array.from(reviewsSlider.querySelectorAll<HTMLElement>('.js-slider-counter'));

                        sliderNavs.forEach((sliderNav) => {
                            createNav(swiper, sliderNav);
                        });

                        counters.forEach((counter) => {
                            createCounter(swiper, counter);
                        });
                    },
                    slideChange: (swiper) => {
                        requestAnimationFrame(() => changeSlide(swiper));
                    },
                    destroy: () => {
                        destroyNav(reviewsSlider);
                    },
                    resize: (swiper) => {
                        if (
                            typeof swiper.params.slidesPerView === 'number' &&
                            slides.length <= swiper.params.slidesPerView
                        ) {
                            swiper.disable();
                        } else {
                            swiper.enable();
                        }
                    },
                    slideChangeTransitionStart: (swiper) => {
                        if (!DEVICE_WIDTH.isDesktop) {
                            swiper.allowTouchMove = false;
                        }
                    },
                },
            });
            map.set(reviewsSlider, slider);
        }
    }
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-related-articles-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
