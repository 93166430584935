let intervalId: NodeJS.Timeout;
let timerEl: HTMLElement | null;

const timerStart = (eventFunction: EventListener, container: HTMLElement) => {
    let seconds = 3;
    timerEl = container.querySelector('.js-code-timer');

    const updateTimer = () => {
        const remainingSeconds = seconds % 60;

        const displayText = `Получить повторный код через 00:${String(remainingSeconds).padStart(2, '0')}`;
        if (timerEl) {
            timerEl.removeEventListener('click', eventFunction);
            timerEl.textContent = displayText;
            if (seconds === 0) {
                clearInterval(intervalId);
                timerEl.textContent = 'Получить повторный код';
                timerEl.classList.add('link', 'link--underlined');
                timerEl.addEventListener('click', eventFunction);
            }
        }

        seconds--;
    };

    updateTimer();

    intervalId = setInterval(updateTimer, 1000);
};

const timerStop = () => {
    if (intervalId && timerEl) {
        clearInterval(intervalId);
        timerEl.textContent = 'Получить повторный код';
        timerEl.classList.remove('link', 'link--underlined');
    }
};

const _module = { timerStart, timerStop };

export default _module;
