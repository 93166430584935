import { handleHeaderMenuClose, handleHeaderMenuOpen } from './header-menu';

const accountButtons = document.querySelectorAll('.js-account-btn');
const menuButton = document.querySelector('.js-menu-btn');
const headerPopupsModal = document.querySelector('.js-hp-modal');

const backButton = document.querySelector('.js-header-back-btn');
const mobileMenuAccountButton = document.querySelector('.js-menu-account-btn');
const mobileMenuCartButton = document.querySelector('.js-menu-cart-btn');

const handleBackButtonClick = () => {
    window.history.back();
};

const handleAccountClick = () => {
    if (headerPopupsModal) {
        headerPopupsModal.classList.add('active');

        const popupsInteractiveElements = Array.from(
            headerPopupsModal.querySelectorAll<HTMLButtonElement | HTMLInputElement>('button, input'),
        );

        popupsInteractiveElements.forEach((element) => {
            element.tabIndex = 0;
        });
    }
};

const init = () => {
    if (menuButton && backButton && mobileMenuAccountButton && mobileMenuCartButton) {
        accountButtons.forEach((button) => {
            button.addEventListener('click', handleAccountClick);
        });
        menuButton.addEventListener('click', handleHeaderMenuOpen);

        backButton.addEventListener('click', handleBackButtonClick);

        mobileMenuAccountButton.addEventListener('click', handleHeaderMenuClose);
        mobileMenuCartButton.addEventListener('click', handleHeaderMenuClose);
    }
};

const _module = { init };

export default _module;
