import gsap from 'gsap';
import { ease1 } from '../../../animations/easings';
import ajaxForms from '@/inits/ajax-forms';
import axios from 'axios';
import createValidator from '@/modules/validator';
import { fetchIMask } from '@/dynamic-modules';
import { openDynamicBanner } from '@/components/dynamic-banner';

const modal = document.querySelector('.js-hp-modal');
const modalContainer = document.querySelector('.js-hp-modal-container');
const viewsContainer = document.querySelector('.js-hp-views-container');
const closeBg = document.querySelector('.js-hp-close-bg');
const close = document.querySelector('.js-hp-close');
const header = document.querySelector<HTMLElement>('.js-header');
const numberAuthForm = document.querySelector('.js-number-auth') as HTMLFormElement;
const numberAuthBtn = numberAuthForm?.querySelector('.js-auth-phone-btn');
const numberRegistrationForm = document.querySelector('.js-number-registration') as HTMLFormElement;
const numberRegistrationFormBtn = numberRegistrationForm?.querySelector('.js-registration-first-phone-send');
const confirmForm = document.querySelector('.js-hp-number-confirm') as HTMLFormElement;
const phoneNumberInner = confirmForm?.querySelector('.js-call-phone-number');
const registrationForm = document.querySelector<HTMLElement>('.js-hp-registration');
const authForm = document.querySelector<HTMLElement>('.js-hp-enter');
const phoneMaskInputs = document.querySelectorAll<HTMLElement>('.js-tel-mask');
let controller = false;

let intervalId: NodeJS.Timeout;
let timerEl: HTMLElement | null;
let reconfirmNumberAction: string;
let phoneNumberInput: HTMLInputElement | null;
let hideTimeout: NodeJS.Timeout;
const currentViewNavHandlers: (() => void)[] = [];
let isViewsAnimating = false;
let retryTimeout: NodeJS.Timeout | null = null;

function timerStart(eventFunction: EventListener, container: HTMLElement) {
    let seconds = 60;
    timerEl = container.querySelector('.js-code-timer');
    const updateTimer = () => {
        const remainingSeconds = seconds % 60;

        const displayText = `Получить повторный код через 00:${String(remainingSeconds).padStart(2, '0')}`;
        if (timerEl) {
            timerEl.removeEventListener('click', eventFunction);
            timerEl.textContent = displayText;
            if (seconds === 0) {
                clearInterval(intervalId);
                timerEl.textContent = 'Получить повторный код';
                timerEl.classList.add('link', 'link--underlined');
                timerEl.addEventListener('click', eventFunction);
                controller = true;
            }
        }

        seconds--;
    };

    updateTimer();

    intervalId = setInterval(updateTimer, 1000);
}

if (registrationForm) {
    numberRegistrationFormBtn?.addEventListener('click', () =>
        confirmNumber('register', numberRegistrationForm, registrationForm),
    );
}

if (authForm) {
    numberAuthBtn?.addEventListener('click', () => confirmNumber('startAuth', numberAuthForm, authForm));
}

const handleViewChange = (to: string) => () => {
    if (isViewsAnimating) {
        return;
    }

    if (viewsContainer) {
        const currentView = viewsContainer.querySelector('.active');

        // Change logic
        if (currentView) {
            const viewNavElements = currentView.querySelectorAll<HTMLElement>('.js-hp-nav');

            viewNavElements.forEach((element, index) => {
                const handleNavChange = currentViewNavHandlers[index];

                element.removeEventListener('click', handleNavChange);
            });
        }

        const nextView = viewsContainer.querySelector<HTMLElement>(`.js-hp-${to}`);

        if (nextView && currentView) {
            isViewsAnimating = true;
            const viewNavElements = nextView.querySelectorAll<HTMLElement>('.js-hp-nav');

            viewNavElements.forEach((element) => {
                const nextViewName = element.dataset.to ?? '';

                const handleNavChange = handleViewChange(nextViewName);
                currentViewNavHandlers.push(handleNavChange);

                element.addEventListener('click', handleNavChange);
            });

            if (modalContainer) {
                if (nextView.dataset.sm) {
                    modalContainer.classList.add('sm');
                } else {
                    modalContainer.classList.remove('sm');
                }
            }

            // Animation
            gsap.to(currentView, { xPercent: -80, ease: ease1, duration: 1, autoAlpha: 0 });
            gsap.to(currentView.querySelector('.js-hp-modal__title'), {
                x: '10vw',
                ease: ease1,
                duration: 1,
                autoAlpha: 0,
                onStart: () => {
                    currentView.classList.remove('active');
                },
                onComplete: () => {
                    isViewsAnimating = false;
                    currentView.classList.add('disabled');
                },
            });
            gsap.fromTo(
                nextView,
                { xPercent: 80, autoAlpha: 0 },
                {
                    xPercent: 0,
                    ease: ease1,
                    duration: 1,
                    autoAlpha: 1,
                },
            );
            gsap.fromTo(
                nextView.querySelector('.hp-modal__title'),
                { x: '-10vw', autoAlpha: 0 },
                { x: '0vw', autoAlpha: 1, ease: ease1, duration: 1 },
            );
            gsap.fromTo(
                nextView.querySelectorAll('.hp-modal__additional-btn'),
                { y: 10, autoAlpha: 0 },
                {
                    y: 0,
                    delay: 0.5,
                    stagger: 0.3,
                    ease: ease1,
                    duration: 0.7,
                    autoAlpha: 1,
                    onStart: () => {
                        nextView.classList.remove('disabled');
                    },
                    onComplete: () => {
                        isViewsAnimating = false;
                        nextView.classList.add('active');
                    },
                },
            );
        }
    }
};

function confirmNumber(action: string, form: HTMLFormElement, transitionForm: HTMLElement) {
    const validator = createValidator(form, {
        scrollToInvalidInputOptions: {
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        },
    });
    phoneNumberInput = form?.querySelector<HTMLInputElement>('.js-input-form');
    const phoneNumber = phoneNumberInput?.value;
    if (phoneNumber) {
        const requestBody = {
            params: {
                params: {
                    phone: phoneNumber,
                    action: action,
                },
            },
        };

        const isFormValid = validator.validate();
        if (isFormValid) {
            axios
                .get('/local/ajax/mobileId.php', requestBody)
                .then((response) => {
                    if (response.data.success && response.data.data.action) {
                        timerStart(reconfirmNumber, confirmForm);
                        numberRegistration(phoneNumber, response.data.data.action, response.data.data.method);
                        gsap.fromTo(
                            transitionForm,
                            {
                                autoAlpha: 1,
                                xPercent: 0,
                            },
                            {
                                xPercent: -80,
                                autoAlpha: 0,
                                ease: ease1,
                                duration: 0.5,
                                onStart: () => {
                                    transitionForm?.classList.remove('active');
                                },
                                onComplete: () => {
                                    transitionForm?.classList.add('disabled');
                                },
                            },
                        );
                        gsap.fromTo(
                            confirmForm,
                            {
                                autoAlpha: 0,
                                xPercent: 80,
                            },
                            {
                                xPercent: 0,
                                autoAlpha: 1,
                                ease: ease1,
                                duration: 0.5,
                                onStart: () => {
                                    confirmForm?.classList.remove('disabled');
                                },
                                onComplete: () => {
                                    confirmForm?.classList.add('active');
                                },
                            },
                        );

                        const displayText = `Мы отправили вам пуш-уведомление на номер ${phoneNumber}.
                        Подтвердите (примите) его пожалуйста. Либо перейдите по ссылке в SMS для подтверждения действия.`;
                        if (phoneNumberInner) {
                            phoneNumberInner.innerHTML = displayText;
                        }
                        if (action === 'register') {
                            reconfirmNumberAction = 'register';
                        } else if (action === 'startAuth') {
                            reconfirmNumberAction = 'startAuth';
                        }
                    } else {
                        clearTimeout(hideTimeout);
                        if (action === 'register') {
                            ajaxForms.showFormMessages(numberRegistrationForm);
                            hideTimeout = setTimeout(() => ajaxForms.hideFormMessages(numberRegistrationForm), 5000);
                            ajaxForms.showFailureMessage(numberRegistrationForm, response.data.message);
                            reconfirmNumberAction = 'register';
                        } else if (action === 'startAuth') {
                            ajaxForms.showFormMessages(form);
                            hideTimeout = setTimeout(() => ajaxForms.hideFormMessages(form), 5000);
                            ajaxForms.showFailureMessage(form, response.data.message);
                            reconfirmNumberAction = 'startAuth';
                        }
                    }
                })
                .catch((error) => {
                    console.error('Ошибка:', error);
                    openDynamicBanner(error.message);
                });
        }
    }
}

function reconfirmNumber() {
    const phoneNumber = phoneNumberInput?.value;

    const requestBody = {
        params: {
            params: {
                phone: phoneNumber,
                action: reconfirmNumberAction,
            },
        },
    };
    axios
        .get('/local/ajax/mobileId.php', requestBody)
        .then((response) => {
            clearTimeout(hideTimeout);
            ajaxForms.showFormMessages(confirmForm);
            if (response.data.success && response.data.data.action && phoneNumber) {
                controller = false;
                numberRegistration(phoneNumber, response.data.data.action, response.data.data.method);
                timerStart(reconfirmNumber, confirmForm);
            }
        })

        .catch((error) => {
            console.error('Ошибка:', error);
            openDynamicBanner(error.message);
        });
}

function numberRegistration(phone: string, action: string, method: string) {
    const requestBody = {
        params: {
            params: {
                phone: phone,
                action: action,
                method: method,
            },
        },
    };

    if (retryTimeout) {
        clearTimeout(retryTimeout);
    }

    axios
        .get('/local/ajax/mobileId.php', requestBody)
        .then((response) => {
            clearTimeout(hideTimeout);
            ajaxForms.showFormMessages(confirmForm);

            if (response.data.success) {
                ajaxForms.showSuccessMessage(confirmForm, response.data.message);
                window.location.href = '/personal/';
            } else if (controller === false) {
                ajaxForms.showFailureMessage(confirmForm, response.data.message);
                retryTimeout = setTimeout(() => {
                    numberRegistration(phone, action, method);
                }, 5000);
            }
        })
        .catch((error) => {
            console.error('Ошибка:', error);
            ajaxForms.showFailureMessage(confirmForm, 'Произошла ошибка при отправке формы');
        });
}

const handleClose = () => {
    if (modal) {
        modal.classList.remove('active');

        const interactiveElements = Array.from(
            modal.querySelectorAll<HTMLButtonElement | HTMLInputElement>('button, input'),
        );

        interactiveElements.forEach((element) => {
            element.tabIndex = -1;
        });
    }
};

const init = () => {
    if (viewsContainer && closeBg && close && modal) {
        const interactiveElements = Array.from(
            modal.querySelectorAll<HTMLButtonElement | HTMLInputElement>('button, input'),
        );

        interactiveElements.forEach((element) => {
            element.tabIndex = -1;
        });

        close.addEventListener('click', handleClose);
        closeBg.addEventListener('click', handleClose);

        const viewNavElements = viewsContainer.querySelectorAll<HTMLElement>('.js-hp-nav');

        viewNavElements.forEach((element) => {
            const nextViewName = element.dataset.to ?? '';
            const handleNavChange = handleViewChange(nextViewName);
            currentViewNavHandlers.push(handleNavChange);

            element.addEventListener('click', handleNavChange);
        });
    }

    if (header) {
        ajaxForms.init(header);
    }

    if (phoneMaskInputs) {
        async function initPhoneMask() {
            const IMask = await fetchIMask();

            phoneMaskInputs.forEach((input) => {
                IMask(input, {
                    mask: '+{7}(000)000-00-00',
                    lazy: false,
                });
            });
        }

        document.addEventListener('DOMContentLoaded', initPhoneMask);
    }
};

const _module = { init };

export default _module;
