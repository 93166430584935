type TEvent = Event & KeyboardEvent & MouseEvent;
type THandlerElement = Element | Document | Window | null | undefined;
type THandlerAssertion = (event: Event) => void;

interface IHTMLElementHandler {
    custom?: false;
    eventName: string;
    element: THandlerElement;
    handler: (event: TEvent) => void;
}

interface ICustomElementHandler {
    custom: true;
    handleDestroy: () => void;
}

const DEFAULT_EVENT_NAME: keyof WindowEventMap = 'click';

export default class PageHandlersService {
    private handlersArray: (IHTMLElementHandler | ICustomElementHandler)[] = [];

    set(target: THandlerElement | string, handler: (event: TEvent) => void, eventName?: keyof WindowEventMap) {
        const handlerEventName = eventName ?? DEFAULT_EVENT_NAME;

        if (typeof target === 'string') {
            const element = document.querySelector(target);

            if (element) {
                element.addEventListener(handlerEventName, handler as THandlerAssertion);

                this.handlersArray.push({ element, handler, eventName: handlerEventName });
            }
        } else {
            if (target) {
                target.addEventListener(handlerEventName, handler as THandlerAssertion);

                this.handlersArray.push({ element: target, handler, eventName: handlerEventName });
            }
        }
    }

    addDestroy(handleDestroy: () => void) {
        this.handlersArray.push({ custom: true, handleDestroy });
    }

    clear() {
        this.handlersArray.forEach((handlersItem) => {
            if (handlersItem.custom) {
                handlersItem.handleDestroy();
            } else {
                if (handlersItem.element) {
                    handlersItem.element.removeEventListener(
                        handlersItem.eventName,
                        handlersItem.handler as THandlerAssertion,
                    );
                }
            }
        });
    }
}
