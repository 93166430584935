const isMobile = window.matchMedia('(max-width: 1024px)').matches;

export default class Marquee3k {
    element: HTMLElement;

    speed: number;

    pausable: boolean;

    reverse: boolean;

    paused: boolean;

    parent: HTMLElement;

    parentProps: DOMRect;

    content: HTMLElement;

    innerContent: string;

    wrapStyles: string;

    offset: number;

    wrapper: HTMLElement;

    contentWidth: number;

    previousWidth: number;

    requiredReps: number;

    rAF: number;

    timer?: NodeJS.Timeout;

    constructor(element: HTMLElement, options: any) {
        this.animate = this.animate.bind(this);

        this.element = element;
        // eslint-disable-next-line no-nested-ternary
        // this.speed = element.dataset.speed ? Number(element.dataset.speed) : isMobile ? 0.4 : 1.3;
        this.speed = options.speed || (isMobile ? 0.4 : 1.3);
        // this.pausable = element.dataset.pausable ? Boolean(element.dataset.pausable) : false;
        this.pausable = false;
        // this.reverse = element.dataset.reverse ? Boolean(element.dataset.reverse) : false;
        this.reverse = false;
        this.paused = false;
        // this.parent = element.parentElement as HTMLElement;
        this.parent = element as HTMLElement;
        this.parentProps = this.parent.getBoundingClientRect();
        this.content = element.children[0] as HTMLElement;
        this.rAF = 0;

        this.innerContent = this.content.innerHTML;
        this.wrapStyles = '';
        this.offset = 0;

        this.previousWidth = this.parentProps.width;

        this.content.classList.add('js-marquee__copy');
        this.content.style.display = 'inline-block';
        this.contentWidth = this.content.getBoundingClientRect().width;

        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('marquee3k__wrapper');
        this.wrapper.style.whiteSpace = 'nowrap';
        this.wrapper.style.willChange = 'transform';

        this.wrapper.appendChild(this.content);
        this.element.appendChild(this.wrapper);

        this.requiredReps =
            this.contentWidth > this.parentProps.width
                ? 2
                : Math.ceil((this.parentProps.width - this.contentWidth) / this.contentWidth) + 1;

        for (let i = 0; i < this.requiredReps; i++) {
            this._createClone();
        }

        if (this.reverse) {
            this.offset = this.contentWidth * -1;
        }

        this.element.classList.add('is-init');

        this.init();
    }

    protected _createClone() {
        const clone = this.content.cloneNode(true) as HTMLElement;
        clone.style.display = 'inline-block';
        clone.classList.add('js-marquee__copy');
        this.wrapper.appendChild(clone);
    }

    protected render() {
        if (!this.paused) {
            const isScrolled = this.reverse ? this.offset < 0 : this.offset > this.contentWidth * -1;
            const direction = this.reverse ? -1 : 1;
            const reset = this.reverse ? this.contentWidth * -1 : 0;

            if (isScrolled) this.offset -= this.speed * direction;
            else this.offset = reset;

            this.wrapper.style.whiteSpace = 'nowrap';
            this.wrapper.style.transform = `translate3d(${this.offset}px, 0, 0)`;
        }
    }

    repopulate(difference: number, isLarger: boolean) {
        this.contentWidth = this.content.getBoundingClientRect().width;

        if (isLarger) {
            const amount = Math.ceil(difference / this.contentWidth) + 1;

            for (let i = 0; i < amount; i++) {
                this._createClone();
            }
        }
    }

    protected animate() {
        this.render();
        this.rAF = window.requestAnimationFrame(this.animate);
    }

    onResize() {
        // buggy?!?!?
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            const isLarger = this.previousWidth < this.parentProps.width;
            const difference = this.parentProps.width - this.previousWidth;
            this.repopulate(difference, isLarger);
            this.previousWidth = this.parentProps.width;
        }, 250);
    }

    protected init() {
        requestAnimationFrame(this.animate);
        window.addEventListener('resize', this.onResize);
    }

    destroy() {
        cancelAnimationFrame(this.rAF);
        window.removeEventListener('resize', this.onResize);
    }
}
