import delegate from 'delegate';
import axios from 'axios';

let delegation: any;

function init(container: HTMLElement | Document = document) {
    delegation = delegate(container, 'a.js-payment-btn', 'click', async (event: any) => {
        event.preventDefault();
        const target = event.delegateTarget as HTMLAnchorElement;

        if (typeof target.href === 'string') {
            const response = await axios.get(target.href).then((res) => res.data);

            if (typeof response === 'object' && response.redirect) {
                window.open(response.redirect, '_blank', 'noopener,noreferrer');
            } else if (typeof response === 'string') {
                target.insertAdjacentHTML('afterend', response);

                /**
                 * Если кнопка внутри коллапса - убеждаемся, что высота контента в коллапсе
                 * будет равна scrollHeight
                 */
                const collapse = target.closest<HTMLElement>('.js-ph-row');
                if (collapse?.classList.contains('active')) {
                    const rowCollapseElement = collapse.querySelector<HTMLElement>('.js-ph-collapse');

                    if (rowCollapseElement) {
                        rowCollapseElement.style.height = `${rowCollapseElement.scrollHeight}px`;
                    }
                }

                target.remove();
            }
        }
    });
}

function destroy() {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const _module = { init, destroy };

export default _module;
