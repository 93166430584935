import lerp from 'lerp';
import gsap from 'gsap';
import { viewport } from '@/viewport';
import { ease1 } from '../animations/easings';

const hoverSelector = `
a:not(.js-not-hoverable),
button:not(.js-not-hoverable),
input:not(.js-not-hoverable),
label:not(.js-not-hoverable),
[role="slider"]:not(.js-not-hoverable),
.js-custom-hoverable
`;

const messageSelector = '.js-cursor-message';

const cursorHiddenSelector = '.js-cursor-hidden';

let cursorElement: HTMLDivElement | null = null;

let clearMessageTextTimeout: NodeJS.Timeout;

const resetCursor = () => {
    if (cursorElement) {
        cursorElement.classList.remove('cursor--point-hidden');

        const messageTextElement = cursorElement.querySelector<HTMLElement>('.cursor__message-text');
        const messageContainerElement = cursorElement.querySelector<HTMLElement>('.cursor__message-container');

        if (messageTextElement && messageContainerElement) {
            if (clearMessageTextTimeout) {
                clearTimeout(clearMessageTextTimeout);
            }

            clearMessageTextTimeout = setTimeout(() => {
                messageTextElement.innerHTML = '';
            }, 300);

            messageContainerElement.classList.add('cursor__message-container--hidden');
        }

        cursorElement.classList.remove('cursor--hidden');
    }
};

const initForPage = (container: Element | Document = document) => {
    resetCursor();

    const cursor = document.querySelector<HTMLElement>('.cursor');
    const header = document.querySelector<HTMLElement>('.js-header');
    const footer = document.querySelector<HTMLElement>('.js-footer');

    if (cursor) {
        cursor.classList.remove('cursor--hover-active');
    }

    const hoverElements = Array.from(container.querySelectorAll(hoverSelector));
    const messageElements = Array.from(container.querySelectorAll(messageSelector));
    const cursorHiddenElements = Array.from(document.querySelectorAll(cursorHiddenSelector));

    if (header && footer) {
        const headerHoverElements = Array.from(header.querySelectorAll(hoverSelector));
        const footerHoverElements = Array.from(footer.querySelectorAll(hoverSelector));

        hoverElements.push(...headerHoverElements, ...footerHoverElements);
    }

    hoverElements.forEach((hoverElement) => {
        hoverElement.addEventListener('mouseenter', handleHoverElementMouseEnter);
        hoverElement.addEventListener('mouseleave', handleHoverElementMouseLeave);
    });

    messageElements.forEach((messageElement) => {
        messageElement.addEventListener('mouseenter', handleMessageElementMouseEnter);
        messageElement.addEventListener('mouseleave', handleMessageElementMouseLeave);
    });

    cursorHiddenElements.forEach((cursorHiddenElement) => {
        cursorHiddenElement.addEventListener('mouseenter', handleCursorHiddenElementMouseEnter);
        cursorHiddenElement.addEventListener('mouseleave', handleCursorHiddenElementMouseLeave);
    });
};

const destroyForPage = () => {
    const hoverElements = Array.from(document.querySelectorAll(hoverSelector));
    const messageElements = Array.from(document.querySelectorAll(messageSelector));
    const cursorHiddenElements = Array.from(document.querySelectorAll(cursorHiddenSelector));

    hoverElements.forEach((hoverElement) => {
        hoverElement.removeEventListener('mouseenter', handleHoverElementMouseEnter);
        hoverElement.removeEventListener('mouseleave', handleHoverElementMouseLeave);
    });

    messageElements.forEach((messageElement) => {
        messageElement.removeEventListener('mouseenter', handleMessageElementMouseEnter);
        messageElement.removeEventListener('mouseleave', handleMessageElementMouseLeave);
    });

    cursorHiddenElements.forEach((cursorHiddenElement) => {
        cursorHiddenElement.removeEventListener('mouseenter', handleCursorHiddenElementMouseEnter);
        cursorHiddenElement.removeEventListener('mouseleave', handleCursorHiddenElementMouseLeave);
    });
};

const init = () => {
    cursorElement = document.createElement('div');
    cursorElement.classList.add('cursor');
    cursorElement.classList.add('cursor--hidden');

    const cursorInner = document.createElement('div');
    cursorInner.className = 'cursor__inner';

    const cursorPoint = document.createElement('div');
    cursorPoint.className = 'cursor__point';

    const messageContainer = document.createElement('div');
    messageContainer.className = 'cursor__message-container cursor__message-container--hidden';

    const messageText = document.createElement('div');
    messageText.className = 'cursor__message-text';

    messageContainer.appendChild(messageText);
    cursorElement.appendChild(messageContainer);
    cursorInner.appendChild(cursorPoint);
    cursorElement.appendChild(cursorInner);
    document.body.appendChild(cursorElement);

    const mouse = {
        x: viewport.width / 2,
        y: viewport.height / 2,
    };

    const lerpedMouse = {
        x: viewport.width / 2,
        y: viewport.height / 2,
    };

    const handleMouseMove = (event: MouseEvent) => {
        mouse.x = event.clientX;
        mouse.y = event.clientY;

        if (cursorElement) {
            cursorElement.classList.remove('cursor--hidden');
        }
    };

    const handleMouseLeave = () => {
        if (cursorElement) {
            cursorElement.classList.add('cursor--hidden');
        }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseLeave);

    function render() {
        lerpedMouse.x = lerp(lerpedMouse.x, mouse.x, 0.1);
        lerpedMouse.y = lerp(lerpedMouse.y, mouse.y, 0.1);

        if (cursorElement) {
            gsap.set(cursorElement, {
                x: lerpedMouse.x,
                y: lerpedMouse.y,
                xPercent: -50,
                yPercent: -50,
            });
        }
    }

    function animate() {
        render();
        requestAnimationFrame(animate);
    }

    requestAnimationFrame(animate);

    const header = document.querySelector('.js-header');
    const footer = document.querySelector('.js-footer');

    if (header && footer) {
        const headerHoverElements = Array.from(header.querySelectorAll(hoverSelector));
        const footerHoverElements = Array.from(footer.querySelectorAll(hoverSelector));

        [...headerHoverElements, ...footerHoverElements].forEach((hoverElement) => {
            hoverElement.addEventListener('mouseenter', handleHoverElementMouseEnter);
            hoverElement.addEventListener('mouseleave', handleHoverElementMouseLeave);
        });
    }
};

const handleHoverElementMouseEnter = () => {
    if (cursorElement) {
        cursorElement.classList.add('cursor--hover-active');
    }
};

const handleHoverElementMouseLeave = () => {
    if (cursorElement) {
        cursorElement.classList.remove('cursor--hover-active');
    }
};

const handleMessageElementMouseEnter = (event: Event) => {
    if (cursorElement) {
        cursorElement.classList.add('cursor--point-hidden');

        const targetElement = event.target as HTMLElement;

        const messageTextElement = cursorElement.querySelector<HTMLElement>('.cursor__message-text');
        const messageContainerElement = cursorElement.querySelector<HTMLElement>('.cursor__message-container');

        if (targetElement && messageTextElement && messageContainerElement) {
            if (clearMessageTextTimeout) {
                clearTimeout(clearMessageTextTimeout);
            }

            const messageText = targetElement.getAttribute('data-cursor-message') ?? '';
            messageTextElement.innerHTML = '';

            const messageWordElements = messageText.split(' ').map((wordText) => {
                const wordElement = document.createElement('span');
                wordElement.className = 'cursor__message-word';
                wordElement.innerText = `${wordText} `;

                return wordElement;
            });

            messageWordElements.forEach((wordElement) => {
                messageTextElement.appendChild(wordElement);
            });

            gsap.fromTo(
                messageWordElements,
                { opacity: 0, yPercent: 50 },
                { opacity: 1, yPercent: 0, delay: 0.2, duration: 0.5, stagger: 0.2, ease: ease1 },
            );

            messageContainerElement.classList.remove('cursor__message-container--hidden');
        }
    }
};

const handleMessageElementMouseLeave = (event: Event) => {
    if (cursorElement) {
        cursorElement.classList.remove('cursor--point-hidden');

        const targetElement = event.target as HTMLElement;

        const messageTextElement = cursorElement.querySelector<HTMLElement>('.cursor__message-text');
        const messageContainerElement = cursorElement.querySelector<HTMLElement>('.cursor__message-container');

        if (targetElement && messageTextElement && messageContainerElement) {
            if (clearMessageTextTimeout) {
                clearTimeout(clearMessageTextTimeout);
            }

            clearMessageTextTimeout = setTimeout(() => {
                messageTextElement.innerHTML = '';
            }, 300);

            messageContainerElement.classList.add('cursor__message-container--hidden');
        }
    }
};

const handleCursorHiddenElementMouseEnter = () => {
    setTimeout(() => {
        if (cursorElement) {
            cursorElement.classList.add('cursor--hidden');
        }
    }, 100);
};

const handleCursorHiddenElementMouseLeave = () => {
    if (cursorElement) {
        cursorElement.classList.remove('cursor--hidden');
    }
};

const _module = { init, initForPage, destroyForPage };

export default _module;
