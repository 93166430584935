import { viewport } from '@/viewport';

export const createLayoutGrid = () => {
    const key = 'k';
    const lineWidth = 1;

    const baseColor = [0, 0, 255];
    const columnColor = `rgba(${baseColor.join(',')}, 0)`;
    const gapColor = `rgba(${baseColor.join(',')}, 0.75)`;

    const grid = document.createElement('div');
    grid.className = 'layout-grid';
    grid.hidden = true;
    document.body.appendChild(grid);

    let currentOffsetX = 0;
    let currentGapWidth = 0;
    let currentColumnWidth = 0;

    function setGridStyles() {
        grid.style.cssText = `
        pointer-events: none;
        position: fixed;
        z-index: 99999999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: repeating-linear-gradient(to right, ${columnColor}, ${columnColor} ${
            currentColumnWidth - (currentGapWidth === 0 ? lineWidth : 0)
        }px, ${gapColor} ${currentColumnWidth - (currentGapWidth === 0 ? lineWidth : 0)}px, ${gapColor} ${
            currentColumnWidth + currentGapWidth
        }px);
        backgroundSize: calc(100% - ${currentOffsetX * 2}px) 100%;
    `;
    }

    function onResize() {
        const docStyles = window.getComputedStyle(document.documentElement);
        const offsetX = docStyles.getPropertyValue('--offsetX')
            ? parseInt(docStyles.getPropertyValue('--offsetX'), 10)
            : 0;
        const gapWidth = parseInt(docStyles.getPropertyValue('--grid-gap'), 10);
        const gridColumns = parseInt(docStyles.getPropertyValue('--grid-columns'), 10);
        const scrollbarWidth = docStyles.getPropertyValue('--scrollbar-width')
            ? parseInt(docStyles.getPropertyValue('--scrollbar-width'), 10)
            : 0;
        const columnWidth =
            (viewport.width - scrollbarWidth - offsetX * 2) / gridColumns -
            gapWidth * ((gridColumns - 1) / gridColumns);

        currentOffsetX = offsetX;
        currentGapWidth = gapWidth;
        currentColumnWidth = columnWidth;

        setGridStyles();
    }

    onResize();
    window.addEventListener('resize', onResize);

    function onKeydown(event: KeyboardEvent) {
        if (event.key === key || event.key === key.toUpperCase()) {
            grid.hidden = !grid.hidden;
        }
    }

    document.addEventListener('keydown', onKeydown);
};
