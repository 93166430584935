const init = (container: HTMLElement | Document = document) => {
    const videos = Array.from(container.querySelectorAll<HTMLVideoElement>('video[autoplay]'));

    videos.forEach((video) => {
        video.play();
    });
};

const _module = { init };

export default _module;
