import gsap from 'gsap';
import { ease1 } from '../../../animations/easings';
import ajaxForms from '@/inits/ajax-forms';
import axios from 'axios';
import phoneModalTimer from '@/utils/phone-code';
import createValidator from '@/modules/validator';
import { openDynamicBanner } from '@/components/dynamic-banner';

const init = (container: Element | Document = document) => {
    const header = container.querySelector<HTMLElement>('.js-header');
    const numberBackBtn = container.querySelector('.js-hp-modal-back-arrow');
    const numberAuthForm = container.querySelector('.js-number-auth') as HTMLFormElement;
    const numberAuthBtn = numberAuthForm?.querySelector('.js-auth-phone-btn');
    const numberRegistrationForm = container.querySelector('.js-number-registration') as HTMLFormElement;
    const numberRegistrationFormBtn = numberRegistrationForm?.querySelector('.js-registration-first-phone-send');
    const confirmForm = container.querySelector('.js-hp-number-confirm') as HTMLFormElement;
    const phoneNumberInner = confirmForm?.querySelector('.js-call-phone-number');
    const registrationForm = container.querySelector<HTMLElement>('.js-system-auth');
    const registerByNumberBtn = container.querySelector('.js-number-registration-btn');

    let controller = false;
    let intervalId: NodeJS.Timeout;
    let timerEl: HTMLElement | null;
    let retryTimeout: NodeJS.Timeout | null = null;

    function timerStart(eventFunction: EventListener, container: HTMLElement) {
        let seconds = 60;
        timerEl = container.querySelector('.js-code-timer');
        const updateTimer = () => {
            const remainingSeconds = seconds % 60;

            const displayText = `Получить повторный код через 00:${String(remainingSeconds).padStart(2, '0')}`;
            if (timerEl) {
                timerEl.removeEventListener('click', eventFunction);
                timerEl.textContent = displayText;
                if (seconds === 0) {
                    clearInterval(intervalId);
                    timerEl.textContent = 'Получить повторный код';
                    timerEl.classList.add('link', 'link--underlined');
                    timerEl.addEventListener('click', eventFunction);
                    controller = true;
                }
            }

            seconds--;
        };

        updateTimer();

        intervalId = setInterval(updateTimer, 1000);
    }

    numberBackBtn?.addEventListener('click', () => {
        gsap.fromTo(
            confirmForm,
            {
                autoAlpha: 1,
                xPercent: 0,
            },
            {
                xPercent: -20,
                autoAlpha: 0,
                ease: ease1,
                duration: 0.5,
                onStart: () => {
                    confirmForm?.classList.remove('active');
                },
                onComplete: () => {
                    confirmForm?.classList.add('disabled');
                },
            },
        );
        gsap.fromTo(
            registrationForm,
            {
                autoAlpha: 0,
                xPercent: 80,
            },
            {
                xPercent: 0,
                autoAlpha: 1,
                ease: ease1,
                duration: 0.5,
                onStart: () => {
                    registrationForm?.classList.remove('disabled');
                },
                onComplete: () => {
                    registrationForm?.classList.add('active');
                },
            },
        );
    });

    let phoneNumberInput: HTMLInputElement | null;
    let hideTimeout: NodeJS.Timeout;
    let reconfirmNumberAction: string;
    let checkAction: string;

    if (registrationForm) {
        numberRegistrationFormBtn?.addEventListener('click', () => {
            confirmNumber('register', numberRegistrationForm, registrationForm);
        });
    }

    if (registrationForm) {
        numberAuthBtn?.addEventListener('click', () => confirmNumber('startAuth', numberAuthForm, registrationForm));
    }

    numberBackBtn?.addEventListener('click', phoneModalTimer.timerStop);

    function confirmNumber(action: string, form: HTMLFormElement, transitionForm: HTMLElement) {
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });
        phoneNumberInput = form?.querySelector<HTMLInputElement>('.js-input-form');
        const phoneNumber = phoneNumberInput?.value;
        if (phoneNumber) {
            const requestBody = {
                params: {
                    params: {
                        phone: phoneNumber,
                        action: action,
                    },
                },
            };

            const isFormValid = validator.validate();
            if (isFormValid) {
                axios
                    .get('/local/ajax/mobileId.php', requestBody)
                    .then((response) => {
                        if (response.data.success && response.data.data.action) {
                            timerStart(reconfirmNumber, confirmForm);
                            numberRegistration(phoneNumber, response.data.data.action, response.data.data.method);
                            gsap.fromTo(
                                transitionForm,
                                {
                                    autoAlpha: 1,
                                    xPercent: 0,
                                },
                                {
                                    xPercent: -80,
                                    autoAlpha: 0,
                                    ease: ease1,
                                    duration: 0.5,
                                    onStart: () => {
                                        transitionForm?.classList.remove('active');
                                    },
                                    onComplete: () => {
                                        transitionForm?.classList.add('disabled');
                                    },
                                },
                            );
                            gsap.fromTo(
                                confirmForm,
                                {
                                    autoAlpha: 0,
                                    xPercent: 80,
                                },
                                {
                                    xPercent: 0,
                                    autoAlpha: 1,
                                    ease: ease1,
                                    duration: 0.5,
                                    onStart: () => {
                                        confirmForm?.classList.remove('disabled');
                                    },
                                    onComplete: () => {
                                        confirmForm?.classList.add('active');
                                    },
                                },
                            );

                            const displayText = `Мы отправили вам пуш-уведомление на номер ${phoneNumber}.
                            Подтвердите (примите) его пожалуйста. Либо перейдите по ссылке в SMS для подтверждения действия.`;
                            if (phoneNumberInner) {
                                phoneNumberInner.innerHTML = displayText;
                            }
                            if (action === 'register') {
                                reconfirmNumberAction = 'register';
                            } else if (action === 'startAuth') {
                                reconfirmNumberAction = 'startAuth';
                            }
                        } else {
                            clearTimeout(hideTimeout);
                            if (action === 'register') {
                                ajaxForms.showFormMessages(numberRegistrationForm);
                                hideTimeout = setTimeout(
                                    () => ajaxForms.hideFormMessages(numberRegistrationForm),
                                    5000,
                                );
                                ajaxForms.showFailureMessage(numberRegistrationForm, response.data.message);
                                reconfirmNumberAction = 'register';
                            } else if (action === 'startAuth') {
                                ajaxForms.showFormMessages(form);
                                hideTimeout = setTimeout(() => ajaxForms.hideFormMessages(form), 5000);
                                ajaxForms.showFailureMessage(form, response.data.message);
                                reconfirmNumberAction = 'startAuth';
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('Ошибка:', error);
                        openDynamicBanner(error.message);
                    });
            }
        }
    }

    function reconfirmNumber() {
        const phoneNumber = phoneNumberInput?.value;

        const requestBody = {
            params: {
                params: {
                    phone: phoneNumber,
                    action: reconfirmNumberAction,
                },
            },
        };

        axios
            .get('/local/ajax/mobileId.php', requestBody)
            .then((response) => {
                clearTimeout(hideTimeout);
                ajaxForms.showFormMessages(confirmForm);
                if (response.data.success && response.data.data.action && phoneNumber) {
                    controller = false
                    numberRegistration(phoneNumber, response.data.data.action, response.data.data.method);
                    timerStart(reconfirmNumber, confirmForm);
                }
            })

            .catch((error) => {
                console.error('Ошибка:', error);
                openDynamicBanner(error.message);
            });
    }

    function numberRegistration(phone: string, action: string, method: string) {
        const requestBody = {
            params: {
                params: {
                    phone: phone,
                    action: action,
                    method: method,
                },
            },
        };

        if (retryTimeout) {
            clearTimeout(retryTimeout);
        }

        axios
            .get('/local/ajax/mobileId.php', requestBody)
            .then((response) => {
                clearTimeout(hideTimeout);
                ajaxForms.showFormMessages(confirmForm);

                if (response.data.success) {
                    ajaxForms.showSuccessMessage(confirmForm, response.data.message);
                    window.location.href = '/personal/';
                } else if (controller === false) {
                    ajaxForms.showFailureMessage(confirmForm, response.data.message);
                    retryTimeout = setTimeout(() => {
                        numberRegistration(phone, action, method);
                    }, 5000);
                }
            })
            .catch((error) => {
                console.error('Ошибка:', error);
                ajaxForms.showFailureMessage(confirmForm, 'Произошла ошибка при отправке формы');
            });
    }

    if (header) {
        ajaxForms.init(header);
    }
};

const _module = { init };

export default _module;
