export const fetchSwiper = () => import(/* webpackChunkName: "swiper" */ 'swiper');

export const fetchAjaxFormSender = () =>
    import(
        '@/modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    );

export const fetchValidator = () =>
    import(
        '@/modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchIMask = async () => import('imask' /* webpackChunkName: "imask" */).then((m) => m.default);

export const fetchContentList = () =>
    import(/* webpackChunkName: "content-list" */ '@chipsadesign/content-list').then((m) => m.default);

export const fetchContentListFilter = () =>
    import(
        /* webpackChunkName: "content-list-filter" */ '@chipsadesign/content-list/dist/modules/ContentListFilter'
    ).then((m) => m.default);

export const fetchIntlTelInput = async () => import('intl-tel-input' /* webpackChunkName: "intl-tel-input" */);
