const init = () => {
    const phoneNumberInputs = Array.from(document.querySelectorAll<HTMLInputElement>('.js-phone-number-cell'));

    phoneNumberInputs.forEach((input, index) => {
        input.addEventListener('input', (event) => {
            const value = event.target.value;

            if (/^[0-9]$/.test(value)) {
                if (index < phoneNumberInputs.length - 1) {
                    setTimeout(() => phoneNumberInputs[index + 1].focus(), 0);
                }
            }

            if (!value && index > 0) {
                setTimeout(() => phoneNumberInputs[index - 1].focus(), 0);
            }
        });

        input.addEventListener('keydown', (event) => {
            if (!(event.key >= '0' && event.key <= '9') && event.key !== 'Backspace') {
                event.preventDefault();
            }

            if (/^[0-9]$/.test(event.key) && index < phoneNumberInputs.length - 1) {
                setTimeout(() => phoneNumberInputs[index + 1].focus(), 0);
            }

            if (event.key === 'Backspace' && index > 0) {
                setTimeout(() => phoneNumberInputs[index - 1].focus(), 0);
            }
        });
    });
};

const _module = { init };

export default _module;
