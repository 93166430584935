import { IView, IViewData } from '@barba/core';

export default function lazyView(namespace: string, promiseFn: () => Promise<IView>) {
    let promise: Promise<IView> | null;

    return {
        namespace,

        beforeEnter(data: IViewData) {
            promise = promiseFn();
            promise.then((view) => view.beforeEnter?.(data));
        },

        afterEnter(data: IViewData) {
            promise?.then((view) => view.afterEnter?.(data));
        },

        beforeLeave(data: IViewData) {
            promise?.then((view) => view.beforeLeave?.(data));
        },

        afterLeave(data: IViewData) {
            promise?.then((view) => view.afterLeave?.(data));
        },
    };
}
