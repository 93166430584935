import Cookies from 'js-cookie';
import { CookiesAgreement } from './CookiesAgreement/CookiesAgreement';
import { MarqueeText } from './MarqueeText/MarqueeText';
import { LitPopupElement } from './LitPopupElement/LitPopupElement';
import { WebShare } from './WebShare/WebShare';
import { Tabs } from './Tabs/Tabs';

export function initCustomElements() {
    if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
        customElements.define('app-cookies-agreement', CookiesAgreement);
    }

    customElements.define('app-marquee', MarqueeText);
    customElements.define('app-lit-popup', LitPopupElement);
    customElements.define('app-web-share', WebShare);
    customElements.define('app-tabs', Tabs);
}
