import gsap from 'gsap';
import { ease1 } from './easings';

export function createInitialBaseTimeline() {
    const headerEls = Array.from(document.querySelectorAll('.js-initial-animation__header-el'));
    const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 1, ease: ease1 },
        // onComplete: () => {
        //     document.documentElement.classList.add('initial-animation-complete');
        // },
    });

    tl.fromTo(headerEls, { opacity: 0, y: -20 }, { opacity: 1, y: 0, stagger: 0.05 });

    return tl;
}
