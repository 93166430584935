import type { ITransitionPage } from '@barba/core';
import gsap from 'gsap';
import { handleMenuLinksActivation } from '../inits/components/header/header-menu';

export default {
    beforeLeave(): Promise<void> {
        return new Promise((resolve) => {
            gsap.to(['[data-barba="wrapper"]', '.js-footer'], {
                duration: 0.7,
                opacity: 0,
                ease: 'power3.out',
                onComplete: () => {
                    setTimeout(resolve, 100);
                },
            });
        });
    },

    async enter(): Promise<void> {
        //
    },

    afterEnter() {
        handleMenuLinksActivation();
        window.scrollTo({ top: 0, behavior: 'auto' });

        gsap.fromTo(
            ['[data-barba="wrapper"]', '.js-footer'],
            { opacity: 0 },
            {
                duration: 0.7,
                opacity: 1,
                ease: 'power3.out',
            },
        );
    },
} as ITransitionPage;
