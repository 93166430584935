import { DEVICE_WIDTH } from '@/utils/viewport-width';
import gsap from 'gsap';
import { ease1 } from '../../../animations/easings';

const MENU_SELECTORS = {
    SCROLL_CONTAINER: '.js-menu-scroll-container',
    BACK_BTN: '.js-menu-back-btn',
    LINK: '.js-menu-link-element',
    MENU_CONTAINER: '.js-menu',
};

const backButtonElement = document.querySelector(MENU_SELECTORS.BACK_BTN);
const scrollContainerElement = document.querySelector<HTMLElement>(MENU_SELECTORS.SCROLL_CONTAINER);
const linkElements = document.querySelectorAll<HTMLLinkElement>(MENU_SELECTORS.LINK);
const menuContainer = document.querySelector(MENU_SELECTORS.MENU_CONTAINER);

let contentShift: number;
let contentScale: number;
let contentRadius: number;

export const handleHeaderMenuOpen = () => {
    if (scrollContainerElement && menuContainer) {
        scrollContainerElement.classList.add('do-not-handle-events');
        gsap.set('.js-header', { y: window.scrollY });
        document.body.classList.add('no-scroll');
        menuContainer.classList.add('visible');

        gsap.set(scrollContainerElement, {
            willChange: 'transform, clip-path',
        });

        requestIdleCallback(() => {
            gsap.fromTo(
                '.menu__link-element.active .menu__link-underline-cover',
                { scaleX: 1 },
                { scaleX: 0, duration: 0.7, delay: 0.5, ease: 'Power1.easeInOut' },
            );

            gsap.fromTo(
                scrollContainerElement,
                {
                    transformOrigin: `right ${window.scrollY + window.innerHeight / 2}px`,
                    clipPath: `inset(${window.scrollY}px 0 ${
                        scrollContainerElement.scrollHeight - (window.scrollY + window.innerHeight)
                    }px round 0px)`,
                    willChange: 'transform, clip-path',
                },
                {
                    duration: 0.8,
                    ease: 'Power1.easeInOut',
                    clipPath: `inset(${window.scrollY}px 0 ${
                        scrollContainerElement.scrollHeight - (window.scrollY + window.innerHeight)
                    }px round ${contentRadius}px)`,
                },
            );

            gsap.to(scrollContainerElement, {
                duration: 0.8,
                ease: 'Power1.easeInOut',
                x: contentShift,
                scale: contentScale,
                onComplete: () => {
                    if (backButtonElement) {
                        backButtonElement.classList.add('clickable');
                    }
                },
            });

            if (document.body.classList.contains('index-page')) {
                const mainStoryContainer = document.querySelector('.js-main-story-container');

                if (mainStoryContainer) {
                    const containerRect = mainStoryContainer.getBoundingClientRect();

                    if (containerRect.y < 0) {
                        gsap.set('.js-main-story-inner', { y: -containerRect.y });
                    }
                }
            }
        });
    }
};

export const handleHeaderMenuClose = () => {
    if (scrollContainerElement && menuContainer && backButtonElement) {
        gsap.fromTo(
            scrollContainerElement,
            {
                clipPath: `inset(${window.scrollY}px 0 ${
                    scrollContainerElement.scrollHeight - (window.scrollY + window.innerHeight)
                }px round ${contentRadius}px)`,
            },
            {
                duration: 0.8,
                ease: 'Power1.easeInOut',
                clipPath: `inset(${window.scrollY}px 0 ${
                    scrollContainerElement.scrollHeight - (window.scrollY + window.innerHeight)
                }px round 0px)`,
                onComplete: () => {
                    gsap.set(scrollContainerElement, { clipPath: 'none' });
                },
            },
        );

        gsap.to(scrollContainerElement, {
            duration: 0.8,
            ease: 'Power1.easeInOut',
            x: 0,
            scale: 1,
            onComplete: () => {
                if (scrollContainerElement && menuContainer) {
                    scrollContainerElement.style.transform = 'none';
                    menuContainer.classList.remove('visible');
                    scrollContainerElement.classList.remove('do-not-handle-events');
                    gsap.set('.js-header', { y: 0 });
                    document.body.classList.remove('no-scroll');

                    gsap.set(scrollContainerElement, {
                        willChange: 'auto',
                    });

                    if (document.body.classList.contains('index-page')) {
                        const mainStoryContainer = document.querySelector('.js-main-story-container');

                        if (mainStoryContainer) {
                            const containerRect = mainStoryContainer.getBoundingClientRect();

                            if (containerRect.y < 0) {
                                gsap.set('.js-main-story-inner', { y: 0 });
                            }
                        }
                    }
                }
            },
        });

        backButtonElement.classList.remove('clickable');
    }
};

export const handleMenuLinksActivation = () => {
    linkElements.forEach((link) => {
        link.classList.remove('active');

        if (window.location.pathname === `${ROOT_PATH}${link.dataset.href}`) {
            link.classList.add('active');
        }
    });
};

const init = () => {
    contentShift = -window.innerWidth + (DEVICE_WIDTH.isMobile ? 30 : 48);
    contentScale = DEVICE_WIDTH.isMobile ? 0.91875 : 0.90625;
    contentRadius = DEVICE_WIDTH.isMobile ? 32 : 48;

    if (backButtonElement) {
        backButtonElement.addEventListener('click', handleHeaderMenuClose);

        linkElements.forEach((link) => {
            link.addEventListener('click', handleHeaderMenuClose);
        });

        handleMenuLinksActivation();
    }
};

const _module = { init };

export default _module;
