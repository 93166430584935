import { DEVICE_WIDTH } from '@/utils/viewport-width';
import gsap from 'gsap';

const footerAnimations: gsap.core.Tween[] = [];
let updateTimeout: NodeJS.Timeout;

const containerResizeObserver = new ResizeObserver((entries) => {
    entries.forEach(() => {
        if (updateTimeout) {
            clearTimeout(updateTimeout);
        }

        updateTimeout = setTimeout(() => {
            updateAnimations();
        }, 100);
    });
});

let isPlayed = false;

const init = () => {
    const footer = document.querySelector('.js-footer');

    footerAnimations.push(
        gsap.fromTo(
            footer,
            {
                y: DEVICE_WIDTH.isDesktop ? 200 : 100,
            },
            {
                y: 0,
                ease: 'power3.ease',
                scrollTrigger: {
                    trigger: footer,
                    once: true,
                    scrub: true,
                    start: 'top bottom',
                    end: DEVICE_WIDTH.isDesktop ? 'top+=400px bottom' : 'top+=200px bottom',
                },
            },
        ),
    );

    if (DEVICE_WIDTH.isDesktop) {
        footerAnimations.push(
            gsap.fromTo(
                '.js-footer-element-c-3',
                { opacity: 0, y: 18 },
                {
                    opacity: 1,
                    y: 0,
                    delay: 0.3,
                    duration: 0.8,
                    scrollTrigger: {
                        trigger: footer,
                    },
                },
            ),
        );
        footerAnimations.push(
            gsap.fromTo(
                '.js-footer-element-c-2',
                { opacity: 0, y: 18 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    delay: 0.43,
                    scrollTrigger: {
                        trigger: footer,
                    },
                },
            ),
        );
        footerAnimations.push(
            gsap.fromTo(
                '.js-footer-element-c-1',
                { opacity: 0, y: 18 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    delay: 0.56,
                    scrollTrigger: {
                        trigger: footer,
                    },
                },
            ),
        );
    } else {
        footerAnimations.push(
            gsap.fromTo(
                '.js-footer-element-mobile',
                { opacity: 0, y: 10 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    delay: 0.3,
                    stagger: 0.13,
                    scrollTrigger: {
                        trigger: footer,
                    },
                },
            ),
        );
        footerAnimations.push(
            gsap.fromTo(
                '.js-footer-element-mobile-secondary',
                { opacity: 0, y: 10 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    delay: 0.69,
                    scrollTrigger: {
                        trigger: footer,
                    },
                },
            ),
        );
    }

    if (DEVICE_WIDTH.isDesktop) {
        containerResizeObserver.observe(document.documentElement);
    } else {
        const mobileContainer = document.querySelector('.menu-scroll-content');

        if (mobileContainer) {
            containerResizeObserver.observe(mobileContainer);
        }
    }
};

const updateAnimations = () => {
    if (!isPlayed) {
        footerAnimations.forEach((animation) => {
            animation.pause().kill();
        });

        init();
    }
};

const updateForPage = () => {
    isPlayed = false;

    updateAnimations();
};

const _module = { init, updateAnimations, updateForPage };

export default _module;
