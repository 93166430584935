export const formatPrice = (amount: number) => {
    const reversedStringArray = `${amount}`.split('').reverse();

    const outputArray: string[] = [];

    reversedStringArray.forEach((symbol, index) => {
        outputArray.push(symbol);

        if ((index + 1) % 3 === 0) {
            outputArray.push(' ');
        }
    });

    return outputArray.reverse().join('');
};
