import gsap from 'gsap';

const map = new Map<HTMLElement, gsap.core.Tween>();

function init(container: Element | Document = document) {
    const parallaxElements = Array.from(container.querySelectorAll<HTMLElement>('.js-parallax'));
    parallaxElements.forEach((section) => {
        const item = section.querySelector('.js-parallax-item');

        const handleEnter = () => gsap.set(item, { willChange: 'transform' });
        const handleLeave = () => gsap.set(item, { willChange: 'auto' });

        const tween = gsap.from(item, {
            y: '50vh',
            ease: 'none',
            scrollTrigger: {
                trigger: section,
                scrub: true,
                start: 'top bottom',
                end: 'bottom top',
                onEnter: handleEnter,
                onEnterBack: handleEnter,
                onLeave: handleLeave,
                onLeaveBack: handleLeave,
            },
        });
        map.set(section, tween);
    });
}

function destroy(container: Element | Document = document) {
    const parallaxElements = Array.from(container.querySelectorAll<HTMLElement>('.js-parallax'));
    parallaxElements.forEach((section) => {
        const tween = map.get(section);

        if (tween) {
            tween.scrollTrigger?.kill();
            tween.kill();
            map.delete(section);
        }
    });
}

function invalidate(container: Element | Document = document) {
    const parallaxElements = Array.from(container.querySelectorAll<HTMLElement>('.js-parallax'));
    parallaxElements.forEach((section) => {
        const tween = map.get(section);

        if (tween) {
            tween.invalidate();
            tween.scrollTrigger?.refresh();
        }
    });
}

const _module = { init, destroy, invalidate };

export default _module;
