import type { Swiper as SwiperType, SwiperOptions } from 'swiper';
import deepMerge from 'lodash.merge';
import { fetchSwiper } from '@/dynamic-modules';

export const map = new Map<Element, any>();

function generateNavButton(direction: 'prev' | 'next') {
    const button = document.createElement('button');
    button.className = `slider-nav-btn slider-nav-btn--${direction} js-slider-${direction}`;
    button.innerHTML = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.202 2.95788L11.0424 1.09002L4.88143e-07 11.7408L4.67297e-07 12.2177L11.0424 22.9082L13.202 21.0006L5.37861 13.4894L24 13.4894L24 10.4691L5.37861 10.4691L13.202 2.95788Z" fill="white"/>
    </svg>
    `;
    button.setAttribute('aria-label', `${direction === 'prev' ? 'Предыдущий' : 'Следующий'} слайд`);
    return button;
}

export function createNav(swiper: SwiperType, sliderNav?: Element | null) {
    if (sliderNav) {
        const navigateBackwards = () => {
            swiper.slidePrev();
        };

        const navigateForwards = () => {
            swiper.slideNext();
        };

        const fragment = document.createDocumentFragment();
        const prevButton = generateNavButton('prev');
        fragment.appendChild(prevButton);
        prevButton.addEventListener('click', navigateBackwards);
        map.set(prevButton, navigateBackwards);

        const nextButton = generateNavButton('next');
        fragment.appendChild(nextButton);
        nextButton.addEventListener('click', navigateForwards);
        map.set(nextButton, navigateForwards);

        sliderNav.appendChild(fragment);
    }
}

export function destroyNav(containerEl: Element) {
    const prevButton = containerEl.querySelector<HTMLElement>('.js-slider-prev');
    const nextButton = containerEl.querySelector<HTMLElement>('.js-slider-next');

    [prevButton, nextButton].forEach((btn) => {
        if (btn) {
            const listener = map.get(btn);

            if (listener) {
                btn.removeEventListener('click', listener);
                map.delete(btn);
            }
        }
    });
}

function init(container: HTMLElement | Document = document, sliderOptions: SwiperOptions = {}) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-logos-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 1) {
            const { Swiper, Lazy } = await fetchSwiper();
            const slider = new Swiper(
                el,
                deepMerge<SwiperOptions, SwiperOptions>(
                    {
                        modules: [Lazy],
                        slidesPerView: 'auto',
                        loopedSlides: 3,
                        loop: true,
                        centeredSlides: true,
                        speed: 500,
                        watchSlidesProgress: true,
                        lazy: {
                            loadPrevNext: true,
                            loadPrevNextAmount: 4,
                            // checkInView: true,
                        },
                        on: {
                            init: (swiper) => {
                                if (
                                    typeof swiper.params.slidesPerView === 'number' &&
                                    slides.length <= swiper.params.slidesPerView
                                ) {
                                    swiper.disable();
                                }
                            },
                            afterInit: (swiper) => {
                                const sliderNavs = Array.from(el.querySelectorAll<HTMLElement>('.js-slider-nav'));
                                sliderNavs.forEach((sliderNav) => {
                                    createNav(swiper, sliderNav);
                                });
                            },
                            destroy: () => {
                                destroyNav(el);
                            },
                            resize: (swiper) => {
                                if (
                                    typeof swiper.params.slidesPerView === 'number' &&
                                    slides.length <= swiper.params.slidesPerView
                                ) {
                                    swiper.disable();
                                } else {
                                    swiper.enable();
                                }
                            },
                        },
                        breakpoints: {
                            1025: {
                                slidesPerView: 5,
                            },
                        },
                    },
                    sliderOptions,
                ),
            );
            map.set(el, slider);
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-logos-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
